<div class="error-dialog">
    <!-- Título acima -->
    <h2 mat-dialog-title>{{ data.title }}</h2>

    <!-- Conteúdo com ícone e mensagem -->
    <div mat-dialog-content class="content">
        <mat-icon class="error-icon" aria-hidden="true">error</mat-icon>
        <p class="message">{{ data.message }}</p>
    </div>

    <br />

    <!-- Botão no contêiner de ações -->
    <div mat-dialog-actions class="actions">
        <button
            mat-button
            style="
                margin-left: auto;
                background-color: #d32f2f;
                color: #fff;
                font-weight: bold;
                text-transform: uppercase;
                padding: 8px 16px;
                border-radius: 4px;
            "
            (click)="close()">
            Fechar
        </button>
    </div>
</div>
