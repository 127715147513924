import { FarmsService } from './../../core/services/admin/farms.service';
import { Farm } from './../../core/models/admin/farms.model';
import {
  filter,
  tap,
  takeUntil,
  debounceTime,
  map,
  delay,
  take,
} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';

@Component({
  selector: 'farm-search',
  templateUrl: './farm-search.component.html',
})
export class FarmSearchComponent implements OnInit {
  // @Input() defaultClass: string; //class="flex-auto gt-xs:pr-3"
  @Input() public controlName: string;
  @Input() public formGroup: FormGroup;
  @Input() public label = 'Fazenda';

  public defaultClass = 'flex-auto gt-xs:pr-3';

  @ViewChild('singleSelect') singleSelect: MatSelect;

  public farmServerSideFilteringCtrl: FormControl = new FormControl();

  /** indicate search operation is in progress */
  public searching: boolean = false;

  /** list of banks filtered after simulating server side search */
  public filteredServerSideFarms: ReplaySubject<Farm[]> = new ReplaySubject<
    Farm[]
  >(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  protected farms: Farm[] = [];

  constructor(private _farmService: FarmsService) {}

  ngOnInit(): void {
    this._farmService.getFarmsMemory().subscribe((farms: Farm[]) => {
      this.farms = farms;

      this.filteredServerSideFarms.next(this.farms.slice());

      this.setInitialValue();
    });

    /*this.farmServerSideFilteringCtrl.valueChanges
      .pipe(
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.farms) {
            return [];
          }

          return this.farms.filter(
            farm =>
              search == '' ||
              farm.farm_name.toLowerCase().includes(search) ||
              farm.company_name?.toLowerCase().includes(search) ||
              farm.identity_name?.toLowerCase().includes(search) ||
              farm.state_register?.includes(search) ||
              farm.city?.toLowerCase().includes(search)
          );
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        filteredFarms => {
          this.searching = false;
          this.filteredServerSideFarms.next(filteredFarms);
        },
        error => {

          this.searching = false;
        }
    );*/

    this.filterFarms();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue(): void {
    this.filteredServerSideFarms
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        //this.singleSelect.compareWith = (a: number, b: number) => a && b && a === b;
      });
  }

  filterFarms() {
    this.farmServerSideFilteringCtrl.valueChanges
      .pipe(
        tap(() => (this.searching = true)),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map((search: string) => {
          if (!this.farms) {
            return [];
          }
          if (!search) {
            return this.farms;
          }

          const lowerSearch = this.removeAccents(search.toLowerCase());

          const normalize = (value: string | undefined | null): string =>
            value
              ? this.removeAccents(value.toLowerCase().replace(/[.\-\/]/g, ''))
              : '';

          return this.farms.filter(farm =>
            [
              normalize(farm.id?.toString()),
              normalize(farm.erp_id?.toString()),
              normalize(farm.cpf),
              normalize(farm.cnpj),
              normalize(farm.state_register),
              normalize(farm.farm_name),
              normalize(farm.company_name),
              normalize(farm.identity_name),
              normalize(farm.city),
              normalize(farm.state),
              normalize(farm.address),
            ].some(field => field.includes(lowerSearch))
          );
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        filteredFarms => {
          this.searching = false;
          this.filteredServerSideFarms.next(filteredFarms);
        },
        error => {
          this.searching = false;
          console.error('Erro ao filtrar fazendas:', error);
        }
      );
  }

  private removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
